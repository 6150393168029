.section-horizontal-gallery-wrap {
  position: relative;
  margin: 5% 0;

  .section-horizontal-gallery {
    padding: 0;
    max-width: none;
    margin-right: 0;
    overflow: hidden;
    margin: 0;
    z-index: 2;
    @media (max-width: 660px) {
      margin: 0;
      width: 100%;
    }
    .section-title {
      color: #fff;
      text-align: left;
      padding: 0;
      margin: 0;
    }
    .slick-list {
      overflow: visible;
    }
    .slick-list,
    .slick-slider {
      height: 660px;
      width: 100%;
      @media (max-width: 660px) {
        height: 440px;
      }
      .slick-arrow {
        &:hover {
          filter: grayscale(100%);
        }
      }
      .slick-prev {
        left: -6%;
        background: url('../../assets/arrowdown.png') no-repeat center center;
        position: absolute;
        bottom: 0;
        top: auto;
        height: 13%;
        bottom: 5%;
        width: 6%;
        right: auto;
        &:before {
          display: none;
        }
        @media (max-width: 600px) {
          display: none;
        }
      }

      .slick-next {
        left: -6%;
        background: url('../../assets/arrowup.png') no-repeat center center;
        position: absolute;
        top: auto;
        width: 6%;
        height: 13%;
        bottom: 20%;
        right: auto;
        &:before {
          display: none;
        }
        @media (max-width: 600px) {
          display: none;
        }
      }
      .slick-track {
        height: 100%;
      }
      .slick-slide {
        max-width: 100%;
        height: 100%;
        color: #15181f;
        .content-image {
          background: white;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;
          height: 620px;
          @media (max-width: 800px) {
            background-size: contain;
          }
          @media (max-width: 660px) {
            height: 420px;
          }
        }
        .content-placeholder {
          background: #a4945b;
          padding: 3% 5%;
          text-align: left;
          margin: -270px auto 0 auto;
          min-height: 300px;
          width: 35%;
          float: right;
          margin-right: 10%;

          @media (max-width: 800px) {
            width: 55%;
            height: auto;
            margin: -205px auto 0 auto;
          }
          @media (max-width: 600px) {
            margin: -174px auto 0 auto;
            padding-bottom: 2em;
            width: 80%;
            border-radius: 0;
            min-height: auto;
          }
          h5 {
            color: #fff;
            font-size: 1.6em;
          }
          p {
            text-align: left;
            margin: 0;
            color: #fff;
            font-size: 0.8em;
            padding: 1em 0;
          }
          .blocks-line {
            border-bottom: 1px solid #fff;
            width: 30%;
            display: block;
            margin-bottom: 1em;
          }
          .action {
            /* Button: */
            background: url('../../assets/side_arrow_brown.svg') no-repeat 90%
              center #fff;
            @media (max-width: 425px) {
              background: #fff;
              padding: 1em;
            }
            background-size: 30px;
            font-size: 0.8em;
            color: #a4945b;
            text-decoration: none;
            &:hover {
              background: #fff;
              text-decoration: none;
            }
          }
        }
      }
      .slick-active .content-placeholder {
        z-index: 999;
      }
    }
  }

  .slick-dots {
    bottom: 0;
  }
}
