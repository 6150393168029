@import '../../../globals.scss';
.content-blocks-alternate {
  margin: 10% 0;
  position: relative;
  &.first {
    margin: 0% 0 0 0;
  }
  .blocks {
    width: 35%;
    padding: 5%;
    position: relative;
    color: #fff;
    @media (max-width: 1224px) {
      width: 45%;
    }
    @media (max-width: 425px) {
      width: 100%;
    }
    h5 {
      font-size: 1.6em;
      margin-bottom: 1.6em;
    }
    .blocks-content {
      font-size: 0.9em;
      margin-bottom: 2em;
    }
    .blocks-line {
      border-bottom: 1px solid #fff;
      width: 35%;
      display: block;
      margin-bottom: 2.5em;
    }
    .blocks-action {
      background: #fff;
      border-radius: 0;
      padding: 0.5em 3em;
      display: inline-block;
      font-size: 0.9em;
      text-transform: uppercase;
      color: $primary;
    }
  }
  .block_0 {
    width: 40%;
    background: $primary;
    float: right;
    padding-bottom: 15%;
    margin-right: 10%;
    margin-top: -540px;
    @media (max-width: 1224px) {
      width: 45%;
    }
    @media (max-width: 990px) {
      margin-top: -300px;
      margin-bottom: 5%;
      padding-bottom: 5%;
    }
    @media (max-width: 365px) {
      width: 55%;
    }
  }
  .block_1 {
    background: $secondary;
    clear: both;
    float: right;
    margin-top: -12%;
    margin-right: 15%;
    @media (max-width: 1224px) {
      margin-right: 5%;
    }
    @media (max-width: 990px) {
      clear: none;
      float: left;
      margin-right: 0%;
      margin-top: 0%;
    }
    a {
      color: $secondary;
    }
  }
  .block_2 {
    background: $forth;
    clear: both;
    float: left;
    margin-top: -32%;
    margin-left: 15%;
    @media (max-width: 1224px) {
      margin-left: 5%;
    }
    @media (max-width: 990px) {
      margin-left: 0%;
      margin-top: 0%;
      clear: none;
    }
    a {
      color: $forth;
    }
  }
  .blocks-image {
    width: 50%;
    height: 500px;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: 13%;
    @media (max-width: 425px) {
      margin-left: 0%;
      width: 100%;
    }
  }

  &.odd {
    .block_0 {
      background: $primary;
      padding-bottom: 15%;
      margin-right: 0%;
      margin-top: 0px;
      float: none;
      @media (max-width: 425px) {
        margin-top: -360px;
        margin-left: 10%;
        padding-bottom: 5%;
      }
    }
    .block_1 {
      background: $secondary;
      margin-top: -12%;
      margin-left: 10%;
      margin-right: 0%;
      float: none;
      clear: none;
      @media (max-width: 990px) {
        float: left;
        margin-top: -10%;
        margin-left: 0%;
      }

      a {
        color: $secondary;
      }
    }
    .block_2 {
      background: $forth;
      margin-top: -32%;
      margin-left: 45%;
      @media (max-width: 1224px) {
        margin-left: 50%;
      }
      float: none;
      clear: none;
      @media (max-width: 990px) {
        margin-left: 0%;
        margin-top: -5%;

        float: left;
      }
      a {
        color: $forth;
      }
    }
    .blocks-image {
      width: 50%;
      margin-left: 0;
      height: 430px;
      float: right;
      position: relative;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      left: 0;
      margin-right: 10%;
      @media (max-width: 1224px) {
        width: 50%;
        margin-top: 5%;
      }
      @media (max-width: 990px) {
        margin-top: -5%;
      }
      @media (max-width: 425px) {
        margin-right: 0%;
        width: 100%;
        float: none;
      }
    }
  }
  &.even {
  }
}
