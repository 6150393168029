@import '../../../globals.scss';
.content-column {
  margin: 3em 0;
  &.first {
    margin: 0 0 3em 0;
    .content {
      margin-top: 0;
      @media (max-width: 580px) {
        margin: 0 2em;
      }
    }
    .highlight {
      margin-top: -12%;
      @media (max-width: 1024px) {
      }
      @media (max-width: 768px) {
        margin-left: 0;
      }
    }
  }
  .highlight {
    background: $secondary;
    padding: 5em 4em;
    color: #fff;
    @media (max-width: 1024px) {
      padding: 2em;
    }
    h5 {
      font-weight: 100 !important;
      color: #fff;
      font-size: 2em;
      margin-bottom: 0.8em;
    }
    p {
      color: #fff;
      font-size: 0.8em;
      line-height: 1.5;
    }
    .blocks-line {
      border-bottom: 1px solid #fff;
      width: 35%;
      display: block;
      margin-top: 2.5em;
    }
  }
  .content {
    margin-top: -7em;
    color: $secondary;
    font-size: 1em;
    padding: 4em 3em 0 2em;
    background: #fff;
    @media (max-width: 800px) {
      margin-top: 0;
      padding: 1em 0;
    }
    ul li {
      margin-left: 1em;
    }
    h4 {
      color: $primary;
      font-weight: 100 !important;
      font-size: 2em;
      padding: 1em 0;
    }
  }
}
