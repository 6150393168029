@import '../../../globals.scss';
.LayoutMenu.wrap {
  color: #fff;
  width: 100%;

  list-style: none;
  margin: 0 0 2.5em 0;
  padding: 0 0 0;
  display: block;
  text-align: center;
  @media (max-width: 800px) {
    display: none;
  }

  li {
    display: inline-block;
    text-align: center;
    span {
      cursor: pointer;
      font-size: 0.9em;
      display: inline-block;
      padding: 1.5em 1.4em;
      letter-spacing: 0.05em;
      -webkit-font-smoothing: subpixel-antialiased;
      color: $primary;
      text-decoration: none;
      font-weight: 400;
      letter-spacing: -0.01em;
      transition: all 0.2s ease-in-out;
      color: $secondary;
      &:hover,
      &.active {
        text-decoration: none;
        color: $primary;
        border-bottom: 0.5em solid $primary;
      }

      @media (max-width: 1024px) {
        padding: 5px 8px;
      }
      @media (max-width: 980px) {
        padding: 5px 8px;
        font-size: 0.9em;
      }
    }

    &:hover {
      .submenu {
        display: block;
      }
    }
  }
}
