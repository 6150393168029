.section-button {
  /* Input: */
  background: url('../../assets/side_arrow.svg') no-repeat 90% center #235758;
  background-size: 30px;
  border-radius: 0;
  padding: 0.6em 4em 0.6em 3em;
  display: inline-block;
  font-size: 0.9em;
  text-transform: uppercase;
  color: #fff;
  transition: background-image 0s;

  a {
    color: #fff;
    text-decoration: none;
  }
  &:hover {
    background: url('../../assets/side_arrow.svg') no-repeat 90% center #fff;
    transition: background-image 0.5s;
    padding: 0.8em 3em;
    text-decoration: none;
    color: #235758;
    border: 1px solid #235758;
    a {
      color: #235758;
    }
  }
}
