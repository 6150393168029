.content-list-alternate-wrapper {
  margin: 3em 0;
  padding: 5% 0 0;
  background: url('../../assets/logobackground.svg') no-repeat 162% center;
  .alternate-actions {
    text-align: center;
  }
  .horizontal-text-block-background {
    margin: 5em 0;
    .row {
      background: #fbfaf9;
    }
  }
}
.home .content-list-alternate-wrapper {
  margin-top: -5%;

  // background: url('../../assets/logo_white.svg') #0a4048 no-repeat;
  background-size: 70%;
}
