@import '../../../globals.scss';
.footer {
  margin-top: 3em;
  padding: 3em;
  background: $footer;
  color: #ad9969;
  font-size: 0.9em;
  .content-wrapper {
    margin-top: 0.6em;
  }
  .logo {
    background-repeat: no-repeat;
    background-position: top left;
    background-size: contain;
    height: 80px;
    max-width: 220px;
  }
  a {
    text-decoration: none;
    color: #ad9969;
    &:hover {
      color: $primary;
    }
  }
}
