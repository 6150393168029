.map-content {
  margin: 3em auto;
  height: 600px;

  &.first {
    position: absolute;
    top: 0;
    width: 100%;
  }
  .leaflet-container {
    height: 100%;
    .leaflet-tile-pane {
      filter: grayscale(100%);
      opacity: 1;
    }
  }
}
