$primary: #104530;
$secondary: #a4945b;
$third: #f4f2ee;
$forth: #7f7347;
$menu: rgba(7, 44, 30, 0.91);
$white: #fff;
$footer: #f4f2ee;

$header-font: 'Playfair Display', serif;
$body-font: 'Roboto', sans-serif;
