.horizontal-text-block-background {
  margin: 5em 0;
  .container {
    padding: 0;
    @media (max-width: 425px) {
      padding: 0 15px;
    }
  }

  .row {
    background: #fbfaf9;
    display: table;
    margin: 0;
    @media (max-width: 425px) {
      display: flex;
    }
    width: 100%;
    min-height: 440px;
    .imageContent {
      display: table-cell;
      width: 50%;
      background-position: center;

      background-size: cover;
      @media (max-width: 425px) {
        width: 100%;
        height: 300px;
        display: block;
        order: 1;
      }
      @media (max-width: 425px) {
        margin: 0;
      }
      padding: 0;
    }
  }
  a {
    color: #a4945b;
  }
  a:hover {
    text-decoration: none;
    color: #104530;
  }
  .noimage {
    background: url('../../assets/placeholder.png') no-repeat !important;
  }
  .content {
    display: table-cell;
    width: 50%;
    vertical-align: middle;
    @media (max-width: 425px) {
      width: 100%;
      display: block;
      order: 2;
    }
    padding: 4em;
    @media (max-width: 992px) {
      padding: 3em;
    }

    h5 {
      color: #104530;
      font-weight: bold;
      font-size: 2.6em;
      word-break: break-word;
      @media (max-width: 425px) {
        font-size: 2em;
      }
    }
    h6 {
      text-transform: uppercase;
      font-weight: bold;
      color: #104530;
      font-size: 0.8em;
      margin-bottom: 5em;
    }

    p {
      font-size: 1em;
      line-height: 1.6;
      color: #a4945b;
      margin: 0 0 2em 0;
      &:hover {
        text-decoration: none;
      }
    }

    ul,
    ol {
      list-style-type: none;
      padding-right: 10%;
      li {
        font-size: 0.9em;
        list-style-type: none;
        margin-bottom: 0.8em;
        color: #104530;

        p {
          color: #104530;
          margin: 0;
        }
        strong,
        b {
          display: block;
        }
      }
    }
    ol {
      padding: 2em 0;
      li {
        background: url('../../assets/check.svg') left 4px no-repeat;
        background-size: 18px;
        line-height: 1.5em;
        padding-left: 2em;
        text-align: left;
        font-size: 1em;
        @media (max-width: 425px) {
          background-size: 10px;
          padding-left: 1.4em;
        }
      }
    }
  }

  &.odd {
    .color-line {
      height: 4px;
      width: 45%;
      background: #12b6c0;
      position: absolute;
      z-index: 2;
      right: -40%;
      left: auto;
      @media (max-width: 600px) {
        display: none;
      }
    }
  }
  &.even {
    .image {
      @media (max-width: 768px) {
        float: none;
      }
    }
  }
}

.home {
  .horizontal-text-block-background {
    .content {
      ul {
        li {
          &:nth-child(n + 3) {
            display: none;
          }
        }
      }
      ol {
        li {
          &:nth-child(n + 3) {
            display: block;
          }
        }
      }
    }
  }
}
