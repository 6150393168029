@import '../../../globals.scss';
.logo-row {
  text-align: center;
  width: 100%;
  padding: 2em;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  margin-bottom: 2em;
  @media (max-width: 460px) {
    border-bottom: none;
    padding: 0;
  }
  .content-wrap {
    width: 100%;
  }

  .partner-label {
    background: #fff;
    color: #032c2f;
    display: inline-block;
    border-radius: 1em;
    padding: 0.2em 0.7em;
    font-size: 0.7em;
  }
  .container {
    display: flex;
    text-align: center;
    justify-content: space-between;
    align-content: space-between;
    align-items: center;
    @media (max-width: 460px) {
      display: block;
    }
    .image {
      max-width: 100%;
      opacity: 0.6;
      height: auto;
      margin-bottom: 0;
      padding: 0 3%;
      @media (max-width: 460px) {
        display: block;
      }
      img {
        max-height: 70px;
        max-width: 100%;

        @media (max-width: 460px) {
          padding: 0;
          margin: 2em 0;
          max-height: auto;
          max-width: auto;
        }
      }
    }
    .icon-holder {
      text-align: center;

      i {
        font-size: 4em;
        color: #50554b;
      }
    }
  }
}
