.lang {
  margin-left: 1em;
  li {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: inline-block;
    a {
      color: #fff;
      font-size: 0.75em !important;
      text-align: center;
      padding-top: 3.2em !important;

      padding-left: 0 !important;
      padding-right: 0 !important;
      margin-right: 0em !important;
      text-transform: uppercase;
      text-decoration: none;
      &.active,
      &:hover {
        font-weight: bold !important;
        border-bottom: none !important;
      }
    }
    &:after {
      content: '|';
      margin: 0 0.3em 0;
    }
    &:first-child {
      &:after {
        margin: 0;
        content: '';
      }
    }
  }
}
.menus.fixed .wrap .menu-items li ul.lang li {
  display: none;
}
