@import './globals.scss';

body,
html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-family: $body-font;
  height: 100%;
  min-height: 100%;

  /* word-break: break-word; */
}

// * {
//   // transition: all 0.2s ease-in-out;
// }
.body-container,
#app,
#root {
  height: 100%;
  min-height: 100%;
}
*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $header-font !important;
  font-weight: 700;
  letter-spacing: -0.01em;
}

@media (max-width: 776px) {
  .row > div {
    margin-bottom: 2em;
  }
}

@media (max-width: 600px) {
  body,
  html {
    font-size: 0.8em;
  }
}

.clearfix:after {
  content: ' '; /* Older browser do not support empty content */
  visibility: hidden;
  display: block;
  height: 0;
  clear: both;
}
/* custom */
@media (min-width: 768px) {
  .container {
    max-width: 1025px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 1240px;
  }
}
@media (max-width: 992px) {
  body {
    font-size: 0.9rem;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1300px;
  }
}
@media (max-width: 600px) {
  /* .row {
    margin-right: -8%;
    margin-left: -8%;
  }
  .row > div {
    margin-right: 8%;
    margin-left: 8%;
  } */
}
