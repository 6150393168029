@import '../../../globals.scss';

.filtermenu {
  padding: 2.5em 0;
  color: $primary;
  h2 {
    color: #fff;
    font-weight: bold;
    font-size: 2.5em;
  }

  .top-content {
    padding: 3em 0 3em 0;

    .section-title {
      margin: 0 0 1em 0;
    }

    ul {
      margin: 0;
      padding: 0;
      text-align: center;
      li {
        list-style-type: none;
        display: inline-block;

        a {
          /* Button: */
          background-color: #fff;
          font-size: 0.9em;
          color: $secondary;
          font-weight: bold;
          margin: 18px 0.5em;
          padding: 0.5em 1em;
          text-decoration: none;
          cursor: pointer;
          i {
            font-size: 0.6em;
          }
          &:hover {
            color: $primary;
            text-decoration: none;
            border-bottom: 2px solid $primary;
            background-color: transparent;
          }
          &.active {
            color: $primary;
            text-decoration: none;
            border: 1px solid $primary;
            background-color: transparent;
          }
        }
      }
    }
  }
  .form-group {
    display: none;
    .selectHelper {
      margin: 2em auto 0 auto;
      width: 60%;
    }
  }

  .row {
    margin-bottom: 2em;
  }

  .menu-item {
    vertical-align: top;
    margin-bottom: 2em;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    &.full {
      height: 400px;
    }
    &.half {
      height: 600px;
    }

    .item-over {
      /* Background: */
      background: rgba(7, 44, 30, 0.81);
      opacity: 1;
      vertical-align: middle;
      height: 100%;
      width: 100%;
      display: none;
      padding: 10%;

      div {
        display: table-cell;
        vertical-align: middle;
        text-align: left;
        @media (max-width: 768px) {
          text-align: center;
        }
        height: 100%;
        .description {
          color: $white;
          padding: 0;
          font-size: 0.9em;
          span {
            display: block;
            font-size: 1.1em;
            font-weight: normal;
            margin-top: 1em;
          }
        }
        .title {
          color: $white;
          font-family: $header-font;
          font-size: 1.6em;
          line-height: 1;
          margin: 0 0 0.5em 0;
          padding: 0;
          text-transform: uppercase;
        }
      }

      &.noImage {
        border-radius: 0.5em;
        div {
          .description {
            color: $primary;
          }
          .title {
            color: $primary;
          }
        }
      }
    }
    &:hover .item-over {
      display: table;
    }
    &.contentCard {
      display: table !important;
      border-radius: 3px;
      height: 100%;
      width: 100%;
      padding: 10%;
      div {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        height: 100%;
      }
      button {
        font-size: 0.9em;
        margin: 1em 0;
        color: #fff;
        &:hover {
          color: #fff;
        }
      }
      h4 {
        color: $secondary;
        margin: 0;
        text-transform: capitalize;
        font-size: 2em;
      }
      p {
        color: $secondary;
        margin: 0;
        font-size: 0.9em;
      }
      a {
        color: #8eca80;
      }
    }
  }
}
