@import '../../../globals.scss';
.contact-form {
  background: #fff;
  padding: 2em 0;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  .map-container {
    width: 100%;
    height: 100%;
    min-height: 450px;
    background: #ddd;
    border: none;
  }
  .contactus-container {
    text-align: left;
    margin: 0 auto;
    @media (max-width: 776px) {
      width: auto;
    }
    h2 {
      text-align: center;
    }

    input,
    textarea {
      margin: 1.2em 0;
      padding: 1.5em;
      font-size: 0.8em;
      /* Input: */
      background: $third;
      // background: #8ECA80;
      // color: #fff;
      color: $primary;
      border: none;
      &::placeholder {
        color: $primary;
        // color:#fff;
      }
    }
    textarea {
      height: 200px;
    }
    .section-button {
      margin-top: 1em;
      padding: 1em;
      font-size: 0.8em;
      text-align: center;
      display: block;
      background: $primary;
      color: $white;
      &:hover {
        color: $white;
      }
      &.enabled {
        display: block;
        cursor: pointer;
      }
      &.disabled {
        display: none;
      }
    }
  }
  input.form-control {
    display: inline-block;
    width: 48%;
    &:first-child {
      margin-right: 3%;
    }
  }
}
