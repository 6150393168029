.content-blocks {
  margin: 3em 0;
  position: relative;
  &.first {
    margin: 0% 0 0 0;
  }
  .blocks {
    width: 35%;
    @media (max-width: 768px) {
      width: 45%;
    }
    @media (max-width: 425px) {
      width: 55%;
    }
    padding: 5%;
    position: relative;
    color: #fff;
    h5 {
      font-size: 1.6em;
      margin-bottom: 1.6em;
    }
    .blocks-content {
      font-size: 0.9em;
      margin-bottom: 2em;
    }
    .blocks-line {
      border-bottom: 1px solid #fff;
      width: 30%;
      display: block;
      margin-bottom: 2.5em;
    }
    .blocks-action {
      background: #fff;
      border-radius: 0;
      padding: 0.5em 3em;
      display: inline-block;
      font-size: 0.9em;
      text-transform: uppercase;
      color: #104530;
    }
  }
  .block_0 {
    background: #104530;
    float: right;
    padding-bottom: 15%;
    margin-right: 10%;
    margin-top: -600px;
    @media (max-width: 768px) {
      margin-top: -468px;
    }
    @media (max-width: 425px) {
      margin-top: -368px;
      margin-right: 4%;
    }
  }
  .block_1 {
    background: #a4945b;
    clear: both;
    float: right;
    margin-top: -12%;
    margin-right: 25%;
    a {
      color: #a4945b;
    }
  }
  .blocks-image {
    width: 50%;
    height: 400px;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    left: 13%;
    margin-top: 10%;
    @media (max-width: 425px) {
      height: 300px;
      left: 2%;
    }
  }
}
