.submenu {
  padding: 0;
  margin: 0;
  display: block;
  text-align: right;

  @media (max-width: 800px) {
    display: none;
  }
  ul {
    padding: 0;
    margin: 0;
    li {
      text-align: right;
      display: inline-block;
      a {
        display: block;
        color: #fff;
        font-size: 0.7em;
        text-transform: uppercase;
        padding: 0em 0.7em;
        -webkit-font-smoothing: subpixel-antialiased;
        .active {
          font-weight: bold;
        }
        &:hover {
          font-weight: bold;
          text-decoration: none;
        }
      }
    }
  }
}
