.wrap-mobile {
  color: #fff;
  text-align: left;
  list-style: none;
  margin: 0;
  padding: 0;
  display: none;

  .lang {
    text-align: right;
    a {
      padding: 0.9em !important;
    }
  }

  @media (max-width: 800px) {
    display: block;
  }

  .bm-burger-bars {
    background: #fff !important;
  }

  .logo a {
    display: inline-block;
    width: 182px;
    height: 75px;
    background: transparent;
    background-image: url('../../../assets/logo_white.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin: 0 0 0;
    padding: 0;

    &:hover {
      background-color: transparent;
      border-radius: 0;
      padding: 0;

      // @media (max-width: 700px) {
      //   background-image: url('/images/logoMobile.png');
      //   height: 200px;
      // }
    }
    // @media (max-width: 800px) {
    //   margin: 0;
    // }
    // @media (max-width: 380px) {
    //   width: 220px;
    // }
    // @media (max-width: 768px) {
    //   height: 45px;
    //   width: 220px;
    // }
  }

  //
  // Burger menu custom styles
  //
  .bm-burger-button {
    position: fixed;
    width: 25px;
    height: 20px;
    right: 36px;
    top: 28px;
  }

  .right .bm-burger-button {
    left: initial;
    right: 36px;
    cursor: pointer;
  }

  .bm-burger-bars {
    background: #fff;
    cursor: pointer;
  }

  .bm-morph-shape {
    fill: #fff;
  }

  .bm-menu {
    background: #fff;

    a {
      color: #41566d;
      font-size: 1em;
      text-transform: capitalize;
      text-decoration: none;
      display: block;
      cursor: pointer;
      border-bottom: 1px solid #41566d;
      &:hover,
      &:focus {
        color: #fff;
        background: #41566d;
      }
    }
  }

  .bm-overlay,
  .bm-menu-wrap {
    top: 0;
  }

  .bm-item-list a {
    padding: 0.8em;

    span {
      margin-left: 10px;
      font-weight: 700;
    }
  }

  //
  // Mixins
  //
  .menu-1 {
    .bm-cross {
      background: #bdc3c7;
    }

    .bm-menu {
      padding: 2.5em 1.5em 0;
      font-size: 1.15em;
    }
  }

  .menu-2 {
    .bm-cross {
      background: #999;
    }

    .bm-menu {
      padding: 3.4em 1em 0;
    }

    a {
      padding: 1em;
    }

    i {
      font-size: 1.7em;
      vertical-align: middle;
      color: #282a35;
    }
  }

  .menu-3 {
    .bm-cross {
      background: #888;
    }

    .bm-menu {
      padding: 2em 1em;
      font-size: 1.15em;
    }

    i {
      opacity: 0.5;
    }

    span {
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 0.75em;
    }
  }

  .menu-4 {
    .bm-cross {
      background: #888;
    }

    h2 {
      margin: 0 auto;
      padding: 2em 1em;
      color: rgba(0, 0, 0, 0.4);
      box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);

      i {
        margin-left: 0.2em;
      }

      span {
        font-size: 1.6em;
        font-weight: 700;
      }
    }

    a {
      padding: 1em;
      text-transform: uppercase;
      transition: background 0.3s, box-shadow 0.3s;
      box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);

      span {
        letter-spacing: 1px;
        font-weight: 400;
      }

      &:hover,
      &:focus {
        background: rgba(0, 0, 0, 0.2);
        box-shadow: inset 0 -1px rgba(0, 0, 0, 0);
        color: #b8b7ad;
      }
    }
  }

  // #slide,
  // #stack,
  // #reveal {
  //   .menu-1;
  // }

  // #bubble,
  // #push {
  //   .menu-2;
  // }

  // #elastic {
  //   .menu-3;
  // }

  // #scaleDown,
  // #scaleRotate,
  // #pushRotate,
  // #fallDown {
  //   .menu-4;
  // }
}
.fixed .wrap-mobile .logo a {
  background-image: url('../../../assets/logo.svg');
}

.fixed .bm-burger-bars {
  background: #fff !important;
}
