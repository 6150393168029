.grid-element {
  padding: 1em;
  .content {
    padding: 1em 0;

    .dimensions {
      display: inline-block;
      font-size: 0.8em;
      margin-top: 1em;
      .unit {
        border-bottom: 1px solid #50554b;
        padding: 0.5em;
        span {
          margin: 0 0.5em;
        }
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
  .image {
    width: 100%;
    height: 200px;
    display: block;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  h6 {
    /* Wireframe connects t: */
    font-size: 1.2em;
    color: #50554b;
    letter-spacing: 0;
    line-height: 1.2;
    text-align: center;
    margin: 0.5em 0;
  }
  p {
    /* Far far away, behind: */
    opacity: 0.8;
    font-size: 0.8em;
    color: #50554b;
    letter-spacing: 0;
    line-height: 1.6;
    padding-right: 10%;
  }
}

.product-item {
  background: #f3f0ee;
  border-radius: 4px;
  min-height: 360px;
  margin: 1em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  position: relative;
  .over {
    display: none;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(142, 202, 128, 0.8);
    color: #fff;
    text-align: center;
    padding: 3em;
    transition: all 1s;
    h6 {
      font-size: 1em;
      margin-top: 3em;
      font-weight: bold;
    }
    .action {
      margin: 1em;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      border-radius: 2em;
      background: #ededed;
      font-size: 0.8em;
      color: #53584e;
      letter-spacing: 0;
      line-height: 1.5;
      padding: 0.5em 1em;
      display: inline-block;
      cursor: pointer;
      &:hover {
        text-decoration: none;
        color: white;
        background: #50554b;
      }
    }
  }
  &:hover {
    .over {
      display: block;
    }
  }
}
