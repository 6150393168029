@import '../../../globals.scss';
.section-title {
  text-align: center;
  .hr {
    height: 80px;
    border-left: 1px solid #a4945b;
    width: 1px;
    display: inline-block;
  }
  h2 {
    font-size: 2.8em;
    font-weight: bold;
    color: #0d3c47;
    letter-spacing: -2;
    margin-bottom: 0.2em;
    span {
      color: #a4945b;
      font-size: 0.35em;
      text-transform: uppercase;
      font-family: $body-font;
      display: block;
    }
  }
}
