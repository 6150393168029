@import '../../../../globals.scss';
.menus {
  color: #fff;
  // position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background: rgba(7, 44, 30, 0.71);
  border-bottom: 1px solid rgba(164, 148, 91, 0.5);
  backdrop-filter: saturate(180%) blur(14px);
  &.fixed {
    position: fixed;

    // background: rgba(80,85,75,0.9);
    padding: 0;
    transition: background-color 300ms linear;
    box-shadow: 2px 2px 13px rgba(0, 0, 0, 0.3);

    z-index: 991;
    .wrap {
      padding: 0;
      margin: 0;
      .logo {
        margin: 0.2em;
        a {
          width: 250px;
          height: 64px;
          margin: 0.5em 0;
          background-repeat: no-repeat;
          background-size: contain;
          background-image: url('../../../assets/logo.svg');
          &:hover,
          &.active {
            color: #41566d;
            border-bottom: none;
            text-decoration: none;
          }
        }
      }

      .menu-items {
        li {
          a {
            padding: 2em 1em;
            color: $white;
            display: inline-block;
            &:hover,
            &.active {
              border-bottom: 0.5em solid #a4945b;
            }
          }
          &:hover,
          &.active {
            .submenu {
              display: block;
            }
          }
        }
        .submenu {
          display: none;
          margin-top: 0;
          a {
            margin: 0.3em 0;
            color: #fff;
          }
        }
      }
    }
  }
  .wrap-mobile .bm-burger-bars {
    background: #fff !important;
  }
  .bm-overlay {
    left: 0;
  }
  .wrap {
    color: #fff;
    text-align: left;
    list-style: none;
    margin: 0 0 0 0;
    padding: 0 0 0;
    display: block;
    @media (max-width: 800px) {
      display: none;
    }

    .menu-items {
      display: block;
      li {
        float: right;
        display: inline-block;
        text-align: center;
        a {
          font-size: 0.9em;
          display: inline-block;
          padding: 2.5em 1.4em;
          letter-spacing: 0.05em;
          -webkit-font-smoothing: subpixel-antialiased;
          color: #fff;
          text-decoration: none;
          font-weight: 400;
          letter-spacing: -0.01em;
          transition: all 0.2s ease-in-out;
          &:hover,
          &.active {
            text-decoration: none;
            border-bottom: 0.5em solid #a4945b;
          }
        }
        // &:first-child {
        //   a {
        //     padding: 5px 0 0 15px;
        //     &:hover,
        //     &.active {
        //       padding: 5px 15px;
        //     }
        //   }
        // }
        &:hover {
          .submenu {
            display: block;
          }
        }
      }
      .submenu {
        display: none;
        position: absolute;
        margin-left: 0;

        li {
          display: block;
          margin: 0;
          float: none;

          a {
            display: block;
            padding: 0.3em 1.7em !important;
            margin: 0.3em 0;
            background: rgba(255, 255, 255, 1);
            border-radius: 1em;
            font-size: 0.8em;
            color: #288190;
            &:hover,
            &.active {
              background: #288190;
              text-decoration: none;
              color: #fff;
            }
          }
        }
      }
    }

    .logo {
      display: block;
      float: left;
      a {
        display: block;
        width: 260px;
        height: 74px;
        margin: 0.8em 0;
        background: transparent;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        padding: 0;

        img {
          height: 50px;
          @media (max-width: 430px) {
            height: 30px;
          }
        }
        h5 {
          font-size: 1.1em;
          color: #ffffff;
          margin: 1em 0 0.5em 0;
          padding: 0;
          @media (max-width: 600px) {
            margin: 0.5em 0 0em 0;
          }
          @media (max-width: 430px) {
            font-size: 0.8em;
          }
        }
        p {
          font-size: 0.8em;
          color: #fff;
          margin: 0;
          padding: 0;
          @media (max-width: 430px) {
            font-size: 0.7em;
          }
        }
      }
    }
  }
}
