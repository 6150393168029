@import '../../../globals.scss';
.text-block-background {
  margin: 3em 0;
  &.first {
    margin: 0;
  }
  position: relative;
  padding: 4.5em 0;
  background-size: cover;
  min-height: 200px;
  transition: all 1s;
  background-repeat: no-repeat;
  @media (max-width: 780px) {
    text-align: center;
  }

  // &:before {
  //   content: '\A';
  //   position: absolute;
  //   width: 100%;
  //   height: 100%;
  //   top: 0;
  //   left: 0;
  //   background: rgba(0, 0, 0, 0.7);
  //   transition: all 1s;
  // }
  .title {
    h5 {
      color: $primary;
      padding: 0 0 0 2em;
      font-size: 3em;
      text-transform: uppercase;
      @media (max-width: 780px) {
        padding: 0;
      }
    }
  }
  .text {
    padding: 0;
    p {
      font-size: 1em;
      line-height: 1;
      margin-bottom: 2em;
      @media (max-width: 425px) {
        text-align: center;
      }
    }
  }
}

.horizontal-text {
  margin: 3em 0;
  &.first {
    margin-top: 0;
  }
  position: relative;
  padding: 4.5em 0;
  transition: all 1s;
  @media (max-width: 800px) {
    font-size: 0.8em;
    padding: 2em 0;
  }
  @media (max-width: 425px) {
    text-align: center;
  }

  .title {
    h5 {
      color: $primary;
      font-weight: bold;
      padding: 0 0 0 1em;
      font-size: 3em;
      word-break: break-word;

      text-transform: uppercase;
      text-align: left;
      @media (max-width: 768px) {
        padding: 0;
      }
      @media (max-width: 425px) {
        font-size: 2em;
        text-align: center;
      }
    }
  }
  .text {
    padding: 0;
    p {
      font-size: 1em;
      line-height: 1.6;
      padding: 0;
      color: $secondary;
      margin-bottom: 2em;
      @media (max-width: 425px) {
        text-align: center;
      }
    }
    ul {
      li {
        margin: 0.7em 0;
        color: $primary;
        list-style-type: none;
        display: inline-block;
        background: url('../../assets/check.svg') left 2px no-repeat;
        background-size: 17px;
        padding-left: 2em;
        line-height: 1.3em;
        text-align: left;
        @media (max-width: 768px) {
          background-size: 10px;
          padding-left: 1.4em;
        }
        @media (max-width: 425px) {
          background-size: 8px;
        }
        path {
          fill: $primary;
        }
        p {
          margin: 0;
          line-height: 1.2;
          color: rgb(16, 69, 48);
        }
        span {
          line-height: 1;
        }
      }
    }
    ol {
      li {
        margin: 0.7em 0;
        color: $primary;
        list-style-type: none;
        display: block;
        background: url('../../assets/check.svg') left 2px no-repeat;
        background-size: 17px;
        padding-left: 2em;
        line-height: 1.3em;
        text-align: left;
        @media (max-width: 768px) {
          padding-left: 1.4em;
          background-size: 10px;
        }
        @media (max-width: 425px) {
          background-size: 8px;
        }
        path {
          fill: $primary;
        }
        p {
          margin: 0;
          line-height: 1.2;
          color: rgb(16, 69, 48);
        }
        span {
        }
      }
    }
  }
}
