@import '../../../globals.scss';

.header {
  height: 100%;
  background: no-repeat center center $primary;
  background-size: cover;
  position: relative;

  transition: height 0.8s;

  .logo-background {
    background: url('../../assets/logobackground.svg') no-repeat right top;
    position: absolute;
    height: 100vh;
    overflow: hidden;
    width: 70%;
    right: 0;
    z-index: 1;
    background-size: 100% 170%;
  }
  .menu-container {
    position: absolute;
    width: 100%;
  }
  .container {
    height: 100%;
    @media (max-width: 1024px) {
      max-width: 95% !important;
    }
  }
  @media (max-width: 600px) {
    height: 95%;
  }
  @media screen and (max-width: 770px), screen and (max-height: 700px) {
    height: 790px;
  }
  @media screen and (max-width: 430px), screen and (max-height: 600px) {
    height: 600px;
  }
  &.inner {
    height: 60%;
    @media (max-width: 600px) {
      height: 65%;
    }
    @media screen and (max-width: 770px), screen and (max-height: 700px) {
      height: 590px;
    }
    @media screen and (max-width: 430px), screen and (max-height: 600px) {
      height: 400px;
    }
    .abolute-elemtns {
      padding: 0;
      // margin-top: -20%;
      text-align: center;
      p {
        padding: 0 20%;
      }
    }
  }

  &.fullscreen {
    height: 95%;
    margin-bottom: 10%;
    .abolute-elemtns {
      background: $primary;
      width: 50%;
      top: auto;
      padding: 1% 0;
      bottom: 0%;
    }
  }

  .logo-wrap {
    position: absolute;
    top: 25%;
    left: 0;
    width: 100%;
    // height: 100%;
    text-align: center;
    span {
      width: 50%;
      margin: 0 auto;
      display: block;
    }
  }

  .menus {
    color: #fff;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;

    &.fixed {
      position: fixed;
      background: $menu;
      // background: rgba(80,85,75,0.9);
      padding: 0;
      transition: background-color 500ms linear;
      .wrap {
        padding: 0;
      }
      .submenu {
        display: none;
      }
      @media (max-width: 960px) {
        .right-menu {
          display: none;
        }
      }
    }
  }

  .read-safe {
    height: 100%;
    opacity: 0.67;
    background: rgba(0, 0, 0, 0.18);
  }
  .read-shadow {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.18);
  }
  .header-slide {
    background-size: cover;
    background-position: center center;
  }
  .slick-list,
  .header-wrapper,
  .header-slide,
  .slick-slider {
    height: 100%;
    width: 100%;

    .slick-dots {
      z-index: 99;
      background: #fff;
      padding: 0;
      position: relative;
      overflow: auto;
      .pager {
        display: inline-block;
        height: 80px;
        width: 80px;
        background-size: cover;
        background-repeat: no-repeat;
      }
      display: flex !important;
      align-content: center;

      li {
        list-style-type: none;
        display: inline-block;
        margin: 0;
        padding: 0;
        height: 80px;
        width: 80px;
        margin: 0 0.2em;
        button {
          font-size: 0;
          line-height: 0;
          display: block;
          padding: 0;
          color: transparent;
          border: 0;
          outline: none;
          background: transparent;
        }
        button:before {
          font-family: slick;
          font-size: 12px;
          line-height: 20px;
          content: '\2022';
          display: block;
          text-align: center;
          opacity: 0.25;
          color: #fff;
          cursor: pointer;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
        &.slick-active {
          button:before {
            opacity: 1;
          }
        }
      }
    }

    .slick-track {
      height: 100%;
      > div {
        opacity: 1;
      }
      > div > div {
        height: 100%;
      }
    }
    .slick-slide {
      max-width: 100%;
      height: 100%;
      color: #fff;

      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }
  }
  .abolute-elemtns {
    // padding: 0;
    // width: 100%;
    // margin-top: -30%;

    padding: 0;
    width: 100vw;
    top: 40%;
    position: absolute;

    @media (max-width: 1024px) {
      margin-left: 5%;
    }
    @media (max-width: 600px) {
      top: 25%;
      width: 80vw;
    }
    .header-text-wrapper {
      max-width: 800px;
      display: flex;
      align-items: center;
      text-align: left;
      h2 {
        font-weight: bold;
        font-size: 3.5em;

        color: #ffffff;
        letter-spacing: 0;
        line-height: 1.2;
        // text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
      }
      h3 {
        font-weight: normal !important;
        font-size: 1em;
        font-family: 'Roboto', 'Open Sans', Arial, sans-serif;
        font-style: italic;

        color: #ffffff;
        letter-spacing: 0;
        line-height: 1.2;
        // text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
      }
      .content {
        margin: 1.5em 0;
        padding: 1em 0;
        border-top: 1px solid #fff;
        p {
          // text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
          font-size: 1.1em;
          max-width: 650px;
          padding: 0;
          font-weight: normal;
          @media (max-width: 600px) {
            padding: 0;
          }
        }
      }
      .action {
        /* Button: */
        display: inline-block;
        /* Download our Catalog: */
        font-weight: bold;
        font-size: 1em;
        color: #fff;
        letter-spacing: 0;
        line-height: 1.5;
        padding: 0.5em 0;
        &:hover {
        }
      }
    }
  }
  .scroll-animation {
    position: absolute;
    bottom: 15%;
    left: 49%;
    margin: auto;
    z-index: 88;
    width: 24px;
    height: 55px;
    opacity: 0.6;
    @media (max-width: 768px) {
      display: none;
    }
    .mousey {
      width: 3px;
      padding: 10px 8px;
      height: 18px;
      border: 2px solid #fff;
      border-radius: 15px;
      opacity: 0.75;
      box-sizing: content-box;
      .scroller {
        width: 3px;
        height: 10px;
        border-radius: 25%;
        background-color: #fff;
        animation-name: scroll;
        animation-duration: 2.2s;
        animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
        animation-iteration-count: infinite;
      }
    }
  }

  @keyframes scroll {
    0% {
      opacity: 0;
    }
    10% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(15px);
      transform: translateY(15px);
      opacity: 0;
    }
  }
}
.nothome .logo-background {
  height: 60vh;
}
.projects .header {
  height: 100%;

  @media (max-width: 600px) {
    height: 95%;
  }
  @media screen and (max-width: 770px), screen and (max-height: 700px) {
    height: 600px;
    .abolute-elemtns {
      top: 25%;
    }
  }
  @media screen and (max-width: 430px), screen and (max-height: 600px) {
    height: 600px;
    .abolute-elemtns {
      top: 25%;
    }
  }
}

.header {
  .arrows {
    text-align: left !important;
    align-items: center;
    margin-right: 2em;
    position: absolute;
    z-index: 99;
    bottom: 0;
    right: 0;
    @media (max-width: 660px) {
      position: relative;
      bottom: auto;
      zoom: 0.8;
      margin-right: 1em;
    }
    .prev {
      display: inline-block;
      width: 40px;
      height: 40px;
      background: url('../../assets/arrow.svg') no-repeat;
      background-size: contain;
      transform: rotate(-90deg);
      margin-right: 0.5em;
      cursor: pointer;
      opacity: 0.7;
      margin-bottom: 0.5em;
      &:hover {
        opacity: 1;
      }
    }
    .next {
      display: inline-block;
      width: 40px;
      height: 40px;
      background: url('../../assets/arrow.svg') no-repeat;
      transform: rotate(90deg);
      background-size: contain;
      margin-bottom: 0.5em;
      cursor: pointer;
      opacity: 0.7;
      &:hover {
        opacity: 1;
      }
    }
  }
}
