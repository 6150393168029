.list-grid {
  margin: 3em 0;
  padding: 3% 0;
  .row {
    justify-content: center;
  }
}
.home .list-grid {
  // background: url('../../assets/logo_white.svg') #0a4048 no-repeat;
  background-size: 60%;
}
