.readmore {
  /* Button: */
  font-size: 0.9em;
  background: url('../../assets/side_arrow_brown.svg') no-repeat right center;
  color: #a4945b;
  font-weight: bold;
  margin: 18px 0;
  padding: 0.5em 4em 0.5em 0em;
  text-decoration: none;
  i {
    font-size: 0.6em;
  }
  @media (max-width: 425px) {
    background: transparent;
    text-align: center;
    padding: 1em;
  }
  &:hover {
    color: #104530;
    text-decoration: none;
  }
}
