@import '../../../globals.scss';
.horizontal-person-block-background {
  margin: 5em 0;
  .row {
    display: table;
    @media (max-width: 768px) {
      display: flex;
    }

    width: 100%;
    background: rgb(251, 250, 249);
    background: linear-gradient(
      0deg,
      rgba(251, 250, 249, 1) 80%,
      rgba(255, 255, 255, 1) 20%
    );
    .imageContent {
      display: table-cell;
      width: 50%;
      @media (max-width: 768px) {
        display: block;
        width: 100%;
        min-height: 300px;
        margin: 0;
        order: 1;
      }
      padding: 0;
      vertical-align: bottom;
      background-repeat: no-repeat;
      background-position: bottom left;
      @media (max-width: 768px) {
        background-position: bottom center;
      }
      background-size: contain;
      filter: grayscale(100%);
      zoom: 1;
      transition: all 1s ease;
    }
    h5 {
      color: $primary;
      font-weight: bold;
      font-size: 2.2em;
      width: 30%;
      margin-bottom: 10%;
      display: inline-block;
      margin-left: 10%;
      @media (max-width: 768px) {
        display: none;
        margin-bottom: 0%;
        margin-left: 5%;
        width: 80%;
        margin-top: 40%;
      }
      @media (max-width: 1208px) {
        display: none;
      }
    }
    &:hover {
      .imageContent {
        filter: grayscale(0%);
      }
    }
  }

  .noimage {
    background: url('../../assets/placeholder.png') no-repeat !important;
  }
  .content {
    display: table-cell;
    width: 50%;
    padding: 7em 4em 0em 4em;
    background: rgb(255, 255, 255);

    @media (max-width: 992px) {
      padding: 2em;
    }
    @media (max-width: 768px) {
      display: block;
      width: 100%;
      margin: 0;
      order: 2;
    }
    h5 {
      display: none;
      @media (max-width: 1208px) {
        display: block;
        width: 100%;
        padding: 0;
        margin: 0;
      }
      @media (max-width: 768px) {
        display: block;
        margin: 0;
        width: 100%;
      }
    }
    .content-role {
      font-style: italic;
      color: $primary;
      font-weight: bold;
      font-size: 0.9em;
    }

    p {
      font-size: 1em;
      line-height: 1.6;
      color: $secondary;
      margin: 0 0 1.2em 0;
    }

    ul {
      list-style-type: disc;
      margin-left: 1em;
      li {
        font-size: 0.9em;
        list-style-type: disc;
        margin-bottom: 0.8em;
        color: $secondary;

        p {
          color: $secondary;
          margin: 0;
        }
        strong {
          display: block;
        }
      }
    }
  }

  &.odd {
    .imageContent {
      padding: 0;
      background-position: bottom right;
      @media (max-width: 768px) {
        background-position: bottom center;
      }
    }
  }
  &.even {
    .imageContent {
      text-align: right;
    }
    h5 {
      margin-bottom: 10%;
      margin-right: 10%;
      @media (max-width: 768px) {
        margin-right: 5%;
        margin-bottom: 0%;
        margin-left: 0%;
      }
    }
  }
}

.home {
  .horizontal-text-block-background {
    .content {
      li {
        &:nth-child(n + 3) {
          display: none;
        }
      }
    }
  }
}
