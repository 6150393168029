.grid-slideshow {
  background: #12b6c0;
  width: 90%;
  padding: 3% 5%;
  position: relative; }
  .grid-slideshow .grid-slides {
    display: flex; }
    .grid-slideshow .grid-slides .grid-slide-element {
      margin: 0 3%; }
      .grid-slideshow .grid-slides .grid-slide-element h6 {
        color: #fff;
        font-size: 1.8em; }
      .grid-slideshow .grid-slides .grid-slide-element p {
        color: #fff; }
      .grid-slideshow .grid-slides .grid-slide-element .readmore {
        color: #fff; }
